<template>
  <!-- sult选项 -->
  <div class="ways">
    <div class="ways-title">
      
    </div>
    <div class="ways-cont">
      <div class="cont-list">
        <div class="list-title">
          <p class="title-right">委托估价</p>
        </div>
      </div>
      <div class="cont-list">
        <div class="list-title">
          <p class="title-left">定制设定</p>
        </div>
      </div>
    </div>
    <div class="ways-cont">
      <div class="cont-list">
        <div class="list-title">
          <p class="title-right">设定领养</p>
        </div>
      </div>
      <div class="cont-list">
        <div class="list-title">
          <p class="title-left">兽装配件</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'technologyWays',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
<style scoped>
.ways{
  padding: 40px 0;
}
.ways-title{
  width: 100%;
  height: 500px;
  background: gray;
}
.ways-cont{
  margin: 15px 20px 0;
  display: flex;
}
.cont-list{
  height: 450px;
  flex: 1;
  margin-right: 20px;
  background: gray;
}
.cont-list:last-child{
  margin-right: 0;
}
.list-title{
  width: 100%;
  height: 230px;
  background: linear-gradient(180deg, #2E2E38 31%, rgba(46, 46, 56, 0) 100%);
}
.title-right{
  text-align: right;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 40px;
  font-weight: 300;
  color: #FFFFFF;
}
.title-left{
  margin-left: 20px;
  padding-top: 10px;
  font-size: 40px;
  font-weight: 300;
  color: #FFFFFF;
}
</style>